import { CommonModule } from "@angular/common";
import {
  booleanAttribute,
  Component,
  EventEmitter,
  forwardRef,
  input,
  Input,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { ROAM_VALIDATORS } from "@app/core/const/validator-error-message.const";
import { RoamLoaderModule } from "@app/shared/components/roam-loader/roam-loader.module";
import { MaterialModule } from "@app/shared/material/material.module";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RoamLoaderModule,
    FormsModule,
    MaterialModule,
  ],
  selector: "app-roam-select",
  templateUrl: "./roam-select.component.html",
  styleUrls: ["./roam-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamSelectComponent),
      multi: true,
    },
  ],
})
export class RoamSelectComponent implements ControlValueAccessor {
  @Output() selectedItem: EventEmitter<any> = new EventEmitter();
  @Input() placeholder: string = "Select";
  @Input() className: string = "";
  @Input({ transform: booleanAttribute }) searchable: boolean = false;
  @Input() hideCheckmark: boolean = true;
  @Input() floatingLabel: boolean = true;
  @Input() public ctrName: string = "";
  @Input() public ctr: AbstractControl | FormControl | null = null;
  @Input() public loading: boolean = false;
  @Input() currentValue: string | undefined;
  @Input({ transform: booleanAttribute }) isMultiple = false;
  @Input() matTriggerText: string = "";
  @Input() reverse: boolean = false;
  @Input() disabled: boolean = false;
  @Input() valueProps: string = "";
  readonly panelClass = input("multiselect reversed config-dropdown-content");
  readonly panelWidth = input("auto");
  public validators: any = ROAM_VALIDATORS;

  _items!: any[];
  filteredItems: any[] = [];
  onChange: any = () => {};
  onTouch: any = () => {};

  get items(): any[] {
    return this._items;
  }

  @Input() set items(value: any[]) {
    this._items = value;
    this.filteredItems = this._items;
  }

  set value(val: string) {
    this.currentValue = val;
    this.onChange(val);
    this.onTouch(val);
  }

  constructor() {
    this.filteredItems = this.items;
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  onSelect(event: MatSelectChange) {
    this.value = event.value;
    this.selectedItem.next(event.value);
    this.ctr?.markAsTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSearch(event: any) {
    this.filteredItems = this.items.filter((data: any) => {
      const filterValue = event.target.value?.toLowerCase().trim() || "";
      return data.name?.toLowerCase().includes(filterValue);
    });
  }

  public getName(id: string): string {
    const index = this.items.findIndex(item => item.id === id);
    return this.items[index].name;
  }
}
