<mat-form-field
  appearance="outline"
  class="full-width roam-field white-bg"
  [class]="className"
  [floatLabel]="!floatingLabel ? 'always' : 'auto'"
  [ngClass]="{
    invalid: ctr && ctr.invalid,
    'ng-touched': ctr && ctr.touched,
    disabled: (ctr && ctr.disabled) || disabled
  }">
  <mat-label *ngIf="floatingLabel">{{ placeholder }}</mat-label>

  <mat-select    
    [placeholder]="placeholder"
    class="roam-menu"
    (selectionChange)="onSelect($event)"
    [hideSingleSelectionIndicator]="hideCheckmark"
    [panelClass]="panelClass()"
    [panelWidth]="panelWidth()"
    [(ngModel)]="currentValue"
    [multiple]="isMultiple">
    <div class="search-wrapper" *ngIf="searchable">
      <input type="search" placeholder="Search" (input)="onSearch($event)" />
      <mat-icon>search</mat-icon>
    </div>

    @if(isMultiple && currentValue && currentValue!.length > 1) {
      <mat-select-trigger>
        <span class="font-13px">{{ currentValue.length || 0 }} Selected</span>
      </mat-select-trigger>
    }

    <mat-option *ngIf="loading">
      <div class="align-center font-14px">
        Loading <app-loader-dot [size]="3"></app-loader-dot>
      </div>
    </mat-option>
    <mat-option
      labelPosition="before"
      *ngFor="let item of filteredItems"
      [value]="valueProps == '' ? item.id : item[valueProps]">
    
      @if(isMultiple) {
        <div class="align-center">
          <p class="roam-text fw-500">{{ item.name }}</p> 
        </div>
      } @else {
        <mat-radio-group [(ngModel)]="currentValue">
          <mat-radio-button [value]="item.id" labelPosition="before">
            <p class="roam-text fw-500">{{ item.name }}</p> 
          </mat-radio-button>
        </mat-radio-group>
      }
    </mat-option>

    @if(filteredItems && filteredItems.length === 0) {
      <mat-option>
       <span class="font-14px roam-text">No results to show</span>
      </mat-option>
    }
    
  </mat-select>
  <button matSuffix mat-icon-button type="button">
    <mat-icon class="arrow-down">expand_more</mat-icon>
  </button>
</mat-form-field>

<div class="mat-error">
  <span *ngFor="let validator of validators[ctrName]">
    {{ validator.message }}
  </span>
</div>
